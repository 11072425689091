<template>
  <div class="main">
    <div class="article_head kefan">
      <el-row :gutter="20">
        <el-col :span="12">
          <div>
            <h4>案例分类</h4>
            <el-divider></el-divider>
          </div>
          <div id="circle" style="width: 80%; height: 280px"></div>
        </el-col>
        <el-col :span="12">
          <div>
            <h4>园区提交数</h4>
            <el-divider></el-divider>
          </div>
          <div id="square" style="width: 80%; height: 280px"></div>
        </el-col>
      </el-row>
    </div>
    <div class="article_data kefan">
      <div class="sx">
        <el-button type="primary" plain @click="toform">添加案例</el-button>
        <el-button type="success" plain>导出列表 </el-button>
      </div>
      <el-table
        :data="tableData"
        ref="multipleTable"
        tooltip-effect="dark"
        stripe
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column prop="id" label="id" width="90"> </el-table-column>
        <el-table-column prop="title" label="案例标题" width="260">
        </el-table-column>
        <el-table-column
          prop="fenlei"
          label="所属分类"
          :filters="fenleiList"
          :filter-method="filterTag"
          filter-placement="bottom-end"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="bumen"
          label="所属部门"
          :filters="bumenList"
          :filter-method="filterbumen"
          filter-placement="bottom-end"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="yuanqu"
          label="所属园区"
          :filters="yuanquList"
          :filter-method="filteryuanqu"
          filter-placement="bottom-end"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="author" label="作者" width="180">
        </el-table-column>
        <el-table-column prop="date" label="发表日期" width="180">
        </el-table-column>
        <el-table-column prop="view" label="浏览数" width="180">
        </el-table-column>
        <el-table-column prop="good" label="点赞数" width="180">
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="routeto(scope.row.id)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pag">
        <span class="demonstration"></span>
        <el-pagination
          background
          layout="total,prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pagesize"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>


    <el-dialog title="新建案例" :visible.sync="dialogFormVisible">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item
          label="案例标题"
          prop="title"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="案例分类"
          prop="fenlei"
          :label-width="formLabelWidth"
        >
          <el-select v-model="form.fenlei" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属园区"
          prop="yuanqu"
          :label-width="formLabelWidth"
        >
          <el-select v-model="form.yuanqu" placeholder="请选择">
            <el-option
              v-for="item in yuanquoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属部门"
          prop="bumen"
          :label-width="formLabelWidth"
        >
          <el-select v-model="form.bumen" placeholder="请选择">
            <el-option
              v-for="item in bumenoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="作者姓名"
          prop="author"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.author"
            autocomplete="off"
            placeholder="请输入作者的真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="事情经过/解决办法" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="form.article_one"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="部门/园区经验分享" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="form.article_two"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="分管领导点评" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="form.article_three"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="公司董事长点评" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入内容"
            v-model="form.article_four"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeform">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import * as echarts from "echarts";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      dialogFormVisible: false,
      formLabelWidth: "150px",
      pagesize: 6,
      currentPage: 1,
      total: 10,
      fenleiList: [],
      yuanquList: [],
      bumenList: [],
      anlinums: [],
      yuanquy: [],
      yuanqux: [],
      form: {
        title: "",
        fenlei: "",
        yuanqu: "",
        bumen: "",
        author: "",
        article_one: "",
        article_two: "",
        article_three: "",
        article_four: "",
      },
      rules: {
        title: [
          { required: true, message: "请输入案例标题", trigger: "blur" },
          {
            min: 3,
            max: 30,
            message: "长度在 3 到 30 个字符",
            trigger: "blur",
          },
        ],
        fenlei: [
          { required: true, message: "请选择案例分类", trigger: "blur" },
        ],
        yuanqu: [{ required: true, message: "请选择园区", trigger: "blur" }],
        bumen: [{ required: true, message: "请选择部门", trigger: "blur" }],
        author: [
          { required: true, message: "请填写作者姓名", trigger: "blur" },
        ],
      },
      
      options: [],
      yuanquoptions: [],
      bumenoptions: [],
      title:""
    };
  },
  mounted() {
    this.getanli(this.title);
    this.getyuanqubumen();
    this.getanlifenlei();
    this.postanlinums();
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 筛选方法
    filterTag(value, row) {
      return row.fenlei === value;
    },
    filterbumen(value, row) {
      return row.bumen === value;
    },
    filteryuanqu(value, row) {
      return row.yuanqu === value;
    },
    // 打开dialog表单
    toform() {
      this.dialogFormVisible = true;
    },
    // 获取园区部门列表
    getyuanqubumen() {
      axios({
        method: "get",
        url: this.$host + this.$api.allyuanqubumeng,
      }).then((res) => {
        this.yuanquoptions = res.data.yuanqu;
        this.bumenoptions = res.data.bumen;
      });
    },
    // 获取已有案例的分类个数
    postanlinums() {
      axios({
        method: "get",
        url: this.$host + this.$api.anlinums,
      })
        .then((res) => {
          // console.log(res.data)
          this.anlinums = res.data.anli;
          this.yuanquy = res.data.yuanquy;
          this.yuanqux = res.data.yuanqux;
        })
        .then(() => {
          var chartDom = document.getElementById("circle");
          window.onresize = function () {
            myChart.resize();
          };
          var myChart = echarts.init(chartDom);
          var option;
          option = {
            tooltip: {
              trigger: "item",
            },
            legend: {
              orient: "vertical",
              top: "top",
            },
            series: [
              {
                name: "案例分类统计",
                type: "pie",
                radius: "50%",
                data: this.anlinums,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                  },
                },
              },
            ],
          };
          option && myChart.setOption(option);
        })
        .then(() => {
          var chartDom = document.getElementById("square");
          var myChart = echarts.init(chartDom);
          var option;

          option = {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            grid: {
              left: "3%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: this.yuanqux,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name:"提交数量",
                data: this.yuanquy,
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(180, 180, 180, 0.2)",
                },
              },
            ],
          };

          option && myChart.setOption(option);
        });
    },
    routeto(id){
      this.$router.push({path:'/article',query:{id:id}})
    },
    // 获取案例分类列表
    getanlifenlei() {
      axios({
        method: "get",
        url: this.$host + this.$api.anlifenlei,
      }).then((res) => {
        this.options = res.data;
      });
    },
    //提交表单
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios({
            method: "post",
            url: this.$host + this.$api.submitanli,
            data: {
              form: this.form,
            },
          }).then((res) => {
            if (res.data == "ok") {
              this.$message.success("案例提交成功，快去看看吧~");
              this.getanli();
              this.dialogFormVisible = false;
              this.form = {
                title: "",
                fenlei: "",
                yuanqu: "",
                bumen: "",
                author: "",
                article_one: "",
                article_two: "",
                article_three: "",
                article_four: "",
              };
            }
          });
        } else {
          // console.log('error submit!!');
          this.$message.error("快看看哪里错了叭！~");
          return false;
        }
      });
    },
    // 关闭表单
    closeform() {
      this.dialogFormVisible = false;
      this.form = {
        title: "",
        fenlei: "",
        yuanqu: "",
        bumen: "",
        author: "",
        article_one: "",
        article_two: "",
        article_three: "",
        article_four: "",
      };
    },
    // 获取案例
    getanli(val) {
      axios({
        method: "post",
        url: this.$host + this.$api.anli,
        data: {
          name:val,
          currentPage: this.currentPage,
          pagesize: this.pagesize,
        },
      }).then((res) => {
        // console.log(res.data)
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.initdata();
      });
    },
    // 分页处理
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getanli();
    },
    // 删除表格
    handleDelete(id) {
      this.$confirm(
        "该操作将永久性地删除该案例所有信息，确定是否删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          axios({
            method: "get",
            url: this.$host + this.$api.deleteanli + "?id=" + id,
          }).then((res) => {
            if (res.data == "ok") {
              this.$message.success("成功删除该案例！");
              this.getanli();
            } else {
              this.$message.error("未查询到此案例，请勿地址栏传输数据");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 初始化数据
    initdata() {
      // 获取分类数据
      this.fenleiList = [];
      this.yuanquList = [];
      this.bumenList = [];
      for (let index = 0; index < this.options.length; index++) {
        var a = {
          text: this.options[index].label,
          value: this.options[index].label,
        };
        this.fenleiList.push(a);
      }
      // 获取园区数据
      for (let index = 0; index < this.yuanquoptions.length; index++) {
        var b = {
          text: this.yuanquoptions[index].label,
          value: this.yuanquoptions[index].label,
        };
        this.yuanquList.push(b);
      }
      // 获取部门数据
      for (let index = 0; index < this.bumenoptions.length; index++) {
        var c = {
          text: this.bumenoptions[index].label,
          value: this.bumenoptions[index].label,
        };
        this.bumenList.push(c);
      }
    },
  },
};
</script>

<style scoped>
.sx {
  display: flex;
  align-items: center;
}
.pag {
  display: flex;
  align-items: center;
  justify-content: right;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
</style>